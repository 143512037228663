import React from 'react';
import { useTranslation } from "react-i18next";

interface PersonalStoryProps {
  aboutReadMore: boolean;
  setAboutReadMore: (value: boolean) => void;
}
const PersonalStory: React.FC<PersonalStoryProps> = ({ aboutReadMore, setAboutReadMore }) => {
  const { t } = useTranslation();
  
  return (
    <div>      
        <div id="about-paragraph-2" className='bg-c1 bg-c1-gradient'>
            <button className='btn btn-read-more p-2 m-3 co-c2 w-auto' onClick={()=>setAboutReadMore(!aboutReadMore)}>&lt;&lt;&lt; {t('personal_story.back')}</button>
            <div className='paragraph my-5'  data-aos="zoom-out" >
                <p className='co-gray2 px-lg-5 fw-bold pb-4'>{t('personal_story.header_1')}: <span className='fw-normal fst-italic'>{t('personal_story.header_2')}</span> </p>
                <p className='co-gray2 px-lg-5'>{t('personal_story.paragraph_1')}</p>
                <p className='co-gray2 px-lg-5'>{t('personal_story.paragraph_2')}</p>
                <p className='co-gray2 px-lg-5'>{t('personal_story.paragraph_3')}</p>
                <p className='co-gray2 px-lg-5'>{t('personal_story.paragraph_4')}</p>
                <div className=" mb-5 pb-5"></div>
            </div>
        </div>
    </div>
  )
}

export default PersonalStory