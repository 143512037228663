import "./scss/base/_reset.scss";
import "./scss/base/_variables.scss";
import "./scss/App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import Body from "./components/Body/Body";
import Leftpipe from "./components/Leftpipe_socialmedia/Leftpipe";
import Rightpipe from "./components/Rightpipe_mail/Rightpipe";
import Navbar from "./components/Navbar/Navbar";
import ToTopButton from "./components/ToTopButton/ToTopButton";
import OpenerAnimation from "./components/OpenerAnimation/OpenerAnimation";
import PersonalStory from "./components/Body/About/PersonalStory";
import PipeInfoPopUp from "./components/Leftpipe_socialmedia/PipeInfoPopUp";
import { StateContext, useStateContext } from './context/StateContext';
import './i18n';

function App(): React.ReactElement {
  const { 
    animationFinished, 
    setAnimationFinished, 
    selectedTheme, 
    setSelectedTheme, 
    aboutReadMore, 
    setAboutReadMore, 
    handlePipeInfo, 
    pipeInfo ,
  } = useStateContext();

  const handleAnimationFinish = () => {
    setAnimationFinished(true);
  };

  return (
    <div id="theme" className={`${selectedTheme}`}>
      { window.innerWidth>992 && <OpenerAnimation onAnimationFinish={handleAnimationFinish} />}
      {animationFinished && (
        <div className={`App bg-c1`}>
          <Navbar selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} />
          <Leftpipe handlePipeInfo={handlePipeInfo} />
          <Rightpipe />
          <ToTopButton />
          <main className="">
            { pipeInfo && <PipeInfoPopUp pipeInfo={pipeInfo} handlePipeInfo={handlePipeInfo}/>}
            {aboutReadMore && <PersonalStory aboutReadMore={aboutReadMore} setAboutReadMore={setAboutReadMore} />}
            <Body
              aboutReadMore={aboutReadMore}
              setAboutReadMore={setAboutReadMore}
              pipeInfo={pipeInfo}
              handlePipeInfo={handlePipeInfo}
            />
          </main>
        </div>
      )}
    </div>
  );
}
export default function WrappedApp(props: any) {
  return (
    <StateContext>
      <App {...props} />
    </StateContext>
  );
}