import React, { useEffect, useState, CSSProperties } from "react";
import { ReactComponent as Logo } from "../../assest/logo_s.svg";
import styles from "../../scss/base/_reset.scss";
import "../../scss/base/_reset.scss";
import "./Navbar.scss";
import { FormCheck } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoLanguage } from "react-icons/io5";
import { FiSun } from "react-icons/fi";
import { FiMoon } from "react-icons/fi";
import { CgClose } from "react-icons/cg";
import { CgMenuLeftAlt } from "react-icons/cg";

type NavbarLinkProps = {
  href: string;
  label: string;
  onClick: () => void;
};

const NavbarLink: React.FC<NavbarLinkProps> = ({ href, label, onClick }) => (
  <li className='nav-item' data-aos='fade-left' data-aos-delay='900'>
    <a className='nav-link co-gray1' href={href} onClick={onClick}>
      {label}
    </a>
  </li>
);

interface NavbarProps {
  selectedTheme: string;
  setSelectedTheme: (theme: string) => void;
}

const Navbar: React.FC<NavbarProps> = ({ selectedTheme, setSelectedTheme }) => {
  // For theme picker bg and scroll color changes
  const [navbarOpen, setNavbarOpen] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language);

  useEffect(() => {
    setTimeout(() => {
      let newLanguage = window.localStorage.getItem("language");
      if (newLanguage) {
        setCurrentLanguage(newLanguage);
        changeLanguage(newLanguage);
      }
    }, 500);
  }, []);

  const handleChangeLanguage = () => {
    let newLanguage = currentLanguage === "en" ? "tr" : "en";
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
    window.localStorage.setItem("language", newLanguage);
  };

  const handleMenuItemClick = () => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <header className={`navbar bg-c1-alpha-high`} data-aos='fade-down' data-aos-duration='500' data-aos-easing='ease-in-sine'>
      <nav className='nav'>
        <a className='logo' href='/'>
          <Logo id='logo' height='50px' />
        </a>

        <div className={`ul-wrapper ${navbarOpen ? "visible" : "hidden"}`}>
          <ul className={`item-list `}>
            <NavbarLink href='#about' label={t("navbar.about")} onClick={handleMenuItemClick} />
            <NavbarLink href='#experience' label={t("navbar.experience")} onClick={handleMenuItemClick} />
            <NavbarLink href='#work' label={t("navbar.portfolio")} onClick={handleMenuItemClick} />
            <NavbarLink href='#blog' label={t("navbar.blog")} onClick={handleMenuItemClick} />
            <NavbarLink href='#meeting' label={t("navbar.meet")} onClick={handleMenuItemClick} />
          </ul>

          <div className='theme-and-language'>
            {currentLanguage === "en" ? (
              <button className='toggle-lang' type='button' onClick={handleChangeLanguage}>
                <div className='position-relative'>
                  <IoLanguage size={24} className='co-gray1' />
                  <img className='position-absolute' src={"/images/icons/lng_en.webp"} alt='Language | English - USA Flag' />
                </div>
              </button>
            ) : (
              <button className='toggle-lang' type='button' onClick={handleChangeLanguage}>
                <div className='position-relative'>
                  <IoLanguage size={24} className='co-gray1' />
                  <img className='position-absolute' src={"/images/icons/lng_tr.webp"} alt='Language | Turkish - Turkey Flag' />
                </div>
              </button>
            )}
            {window.screen.width > 780 && (
              <SwitchTheme selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} checked={checked} setChecked={setChecked} />
            )}
            {window.screen.width < 780 && (
              <SwitchTheme selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} checked={checked} setChecked={setChecked} />
            )}{" "}
            {/* Can move to desired location */}
          </div>
        </div>

        {window.screen.width < 780 && (
          <button className='navbar-toggler' onClick={() => setNavbarOpen(!navbarOpen)} type='button' aria-label='Toggle navigation'>
            <div className='co-gray1'>{navbarOpen ? <CgClose size={28} className='co-gray1' /> : <CgMenuLeftAlt size={28} className='co-gray1' />}</div>
          </button>
        )}
      </nav>
    </header>
  );
};

export default Navbar;

function isAfterSixPM() {
  const currentDate = new Date();
  const hours = currentDate.getHours();
  return hours >= 18;
}

type SwitchThemeProps = {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTheme: string;
  setSelectedTheme: (theme: string) => void;
};

const SwitchTheme: React.FC<SwitchThemeProps> = ({ checked, setChecked, selectedTheme, setSelectedTheme }) => {
  // For theme picker bg and scroll color changes
  const theme1_bg = { color: styles.theme1_text };

  const handleChange = () => {
    setChecked((checked) => !checked);
    handleThemeChange(checked);
  };
  const handleThemeChange = (theme: boolean) => {
    if (theme === false) {
      setSelectedTheme("theme1");
      document.querySelector<HTMLElement>(":root")!.style.setProperty("--c1", "#004147");
      document.querySelector<HTMLElement>(":root")!.style.setProperty("--c2", "#0e131a");
    } else if (theme === true) {
      setSelectedTheme("theme4");
      document.querySelector<HTMLElement>(":root")!.style.setProperty("--c1", "#fff");
      document.querySelector<HTMLElement>(":root")!.style.setProperty("--c2", "#dedede");
    }
  };

  useEffect(() => {
    const isNight = isAfterSixPM();
    if (isNight) {
      handleChange();
    }
  }, []);

  // for Lighthouse accessibility score about form labels:
  const srOnly: CSSProperties = {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: '0',
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    border: '0',
  };

  return (
    <>
      <label htmlFor='themeSwitch' style={srOnly}>
        Toggle theme
      </label>
      <FormCheck
        className='theme-toggler co-c2'
        type='switch'
        id='themeSwitch'
        label={!checked ? <FiSun size={18} className='co-c2' /> : <FiMoon size={18} className='co-c2' />}
        checked={checked}
        onChange={handleChange}
      />
    </>
  );
};
