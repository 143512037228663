import React from 'react'

interface InfoPopUpProps {
  pipeInfo: boolean;
  handlePipeInfo: (value: boolean) => void;
}

const versionData = [
  { versionName: "v4", versionChanges: ["Language feauture"]},
  { versionName: "v3", versionChanges: ["Better IU", "Simpler components","Time-based auto theme"]},
  { versionName: "v2", versionChanges: ["Theme feauture","Calendly frame","Animations"]},
  { versionName: "v1", versionChanges: ["MVP model","3D rendering"]},
]

const PipeInfoPopUp: React.FC<InfoPopUpProps> = ({ pipeInfo, handlePipeInfo }) => {
  return (
    <div>
        <div id="pipe-info" className={`c1-shadow2 ${pipeInfo ? "show" : ""}`}>
          <div className="container" data-aos="zoom-in" data-aos-duration="700">
            <div className='d-flex justify-content-end'>
            <button
              className="btn-trans co-whity p-3 m-0"
              onClick={() => handlePipeInfo(false)}
              name="info-btn"
            ><h3>✕</h3>
            </button>
            </div>

            <div className="co-whity px-4 mb-5">
              <p className="">Used tools & technologies: <div className='co-c2'>Typescript, React, Sass</div></p>
              <p className="">If you want the repo, just ask me</p>
              <p className="">Built and developed by Selman Koral from scratch.</p>
              {versionData.map((item, i)=> (
                <div className="" key={i}>
                  <span className="version-header">-- {item.versionName} :</span>
                  <ul className="">
                    {item.versionChanges.map((update, j) => (
                        <li key={j}>{update}</li>
                    ))}
                  </ul>
                </div>
                
              ))}
            </div>
          </div>
        </div>
    </div>
  )
}

export default PipeInfoPopUp