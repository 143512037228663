import React, { lazy, Suspense, useEffect } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import ScheduleMeeting from './ScheduleMeeting/ScheduleMeeting';
import { useTranslation } from "react-i18next";

// Lazy load components
const Hero = lazy(() => import('./Hero/Hero'));
const About = lazy(() => import('./About/About'));
const Experience = lazy(() => import('./Experience/Experience'));
const Card = lazy(() => import('./Projects_card/Card'));
const Blog = lazy(() => import('./Blog/Blog'));
const Contact = lazy(() => import('./Contact/Contact'));

interface Props {
    aboutReadMore: boolean;
    setAboutReadMore:  (value: boolean) => void;
    pipeInfo: boolean;
    handlePipeInfo: (value: boolean) => void;
}

const Body: React.FC<Props> = ({ pipeInfo, handlePipeInfo, aboutReadMore, setAboutReadMore  }) => {

  const { t } = useTranslation();
  useEffect(() => {
    Aos.init({ duration: 500, once: true });
  }, []);

  return (
    <>
      {!aboutReadMore && 
      <section className="body">
        <Hero />
        <Suspense fallback={<div>{t("general.loading")}...</div>}>
          <About aboutReadMore={aboutReadMore} setAboutReadMore={setAboutReadMore} />
          <Experience />
          <Card />
          <Blog />
          <ScheduleMeeting />
          <Contact pipeInfo={pipeInfo} handlePipeInfo={handlePipeInfo} />
          <footer className='pt-5 pb-2 pb-lg-4 mt-5 co-gray3 text-center' style={{fontSize:"smaller"}}>
            {false ? "2023 © Built and developed by Selman Koral" : ""}
          </footer>
        </Suspense>
      </section>
      }
    </>
  );
};

export default Body;
