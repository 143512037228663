import React from 'react';
import './ScheduleMeeting.scss';
import { InlineWidget } from "react-calendly";
import { useTranslation } from "react-i18next";

const ScheduleMeeting: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div id='meeting' className="row calendly-meeting-wrapper pb-5 pb-lg-0" data-aos="fade-up">
          

          <div className="meeting-title col-12 col-md-1 px-5"></div>
        <div className="meeting-title col-12 col-md-6 px-5">
          <h2 className='co-gray1'>{t('meet.span_1')} <span className="co-c2">{t('meet.span_2')}</span> ?</h2>
          <p className='co-gray2 px-3'>{t('meet.text_desc')}</p>
        </div>
        <div className="calendly-meeting-container col-12 col-md-5 bg-c1 c1-shadow1" data-aos="slide-left">
          <InlineWidget url="https://calendly.com/selmankoral/15min?hide_gdpr_banner=1"  />
        </div>
    </div>
  )
};

export default ScheduleMeeting;
