import React from "react";
import "./Rightpipe.scss";

const Rightpipe: React.FC = () => {
  return (
    <section className="rightpipe">
      <a href="mailto:selmankorall@gmail.com" rel="noopener noreferrer" target="_blank">
        <p className="vertical-text co-gray3">selmankorall@gmail.com</p>
      </a>      
    </section>
  );
};

export default Rightpipe;
