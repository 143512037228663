import React, { useState, useEffect } from "react";
import "./OpenerAnimation.scss";
import { ReactComponent as Logo } from "../../assest/logo_s.svg";

interface OpenerAnimationProps {
  onAnimationFinish: (value: boolean) => void;
}

const OpenerAnimation: React.FC<OpenerAnimationProps> = ({ onAnimationFinish }) => {
  const [logoVisible, setLogoVisible] = useState<boolean>(true);

  useEffect(() => {
    const logo = document.querySelector("#logo");
    logo?.addEventListener("animationend", () => {
      setLogoVisible(false);
      onAnimationFinish(true);
    });
  }, [onAnimationFinish]);

  useEffect(() => {
    if (!logoVisible) {document.body.style.overflow = "overlay";}
  }, [logoVisible]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <div>
      {logoVisible && (
        <div className='opener-animation'>
          <Logo id='logo' className='logo' height='60px' />
        </div>
      )}
    </div>
  );
};

export default OpenerAnimation;
