import React, { createContext, useContext, useState } from 'react';

interface StateContextValue {
  aboutReadMore: boolean;
  setAboutReadMore: React.Dispatch<React.SetStateAction<boolean>>;
  animationFinished: boolean;
  setAnimationFinished: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTheme: string;
  setSelectedTheme: React.Dispatch<React.SetStateAction<string>>;
  pipeInfo: boolean;
  setPipeInfo: React.Dispatch<React.SetStateAction<boolean>>;
  handlePipeInfo: (value: boolean) => void;
}

const Context = createContext<StateContextValue | undefined>(undefined);

interface StateContextProps {
  children: React.ReactNode;
}

export const StateContext: React.FC<StateContextProps> = ({ children }) => {
  
  const [aboutReadMore, setAboutReadMore] = useState(false);
  const [animationFinished, setAnimationFinished] = useState<boolean>(true);
  const [selectedTheme, setSelectedTheme] = useState<string>('theme4');
  const [pipeInfo, setPipeInfo] = useState<boolean>(false);

  const handlePipeInfo = (value: boolean): void => {
    setPipeInfo(value);
  };

  const contextValue: StateContextValue = {
    aboutReadMore,
    setAboutReadMore,
    animationFinished,
    setAnimationFinished,
    selectedTheme,
    setSelectedTheme,
    pipeInfo,
    setPipeInfo,
    handlePipeInfo
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useStateContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useStateContext must be used within a StateContextProvider');
  }
  return context;
};
