import React, { useEffect } from "react";
import "./ToTopButton.scss";
import { IoIosArrowUp } from "react-icons/io";

const ToTopButton: React.FC = () => {
  useEffect(() => {
    function handleToTop() {
      let backtotop = document.querySelector(".back-to-top") as HTMLElement;
      if (window.scrollY > 100) {
        backtotop.classList.add("active");
      } else {
        backtotop.classList.remove("active");
      }
    }
    window.addEventListener("scroll", handleToTop);
    return () => {
      window.removeEventListener("scroll", handleToTop);
    };
  }, []);
  const handleScrollToTop = () => {
    if (window) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  return (
    <button onClick={handleScrollToTop} className='back-to-top border-0'>
      <IoIosArrowUp size={42} className='co-c1 c1-shadow2 p-2 rounded-pill' />
    </button>
  );
};

export default ToTopButton;
